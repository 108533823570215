<template>
  <custom-modal :opened="opened"
                :max-width="1110"
                class-name="competitors-finder-modal"
                :transparent-bg="transparentBg"
                @closeModal="closeModalHandler('showAppDetailsModal')">
    <div class="modal-message">
      <keyword-explorer-component
          :classes="'mobile-explorer'"
          :currentKeyword="currentKeyword"
          :update-explore-component="updateExploreComponent">
        <slot></slot>
        <template v-slot:bottom-link>
          <router-link
              tag="div"
              :to="{name: 'KeywordExplorer'}"
              class="explorer-link">
            <span>Open in Keyword Explorer</span>
            <svg-icon icon="arrow-right"/></router-link>
        </template>
      </keyword-explorer-component>
    </div>
  </custom-modal>
</template>

<script>
import KeywordExplorerComponent from "@/components/KeywordExplorerComponent/index.vue";

export default {
  name: "ExplorerModal",
  components: {
    'keyword-explorer-component': KeywordExplorerComponent,
  },
  emits: ['modal-closed', 'competitor-changed'],
  props: {
    opened: {
      type: Boolean,
    },
    currentKeyword: {
      type: String,
    },
    changeCompetitorIn: {
      type: Boolean,
    },
    transparentBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateExploreComponent: 0,
    }
  },
  methods: {
    closeModalHandler(modalName) {
      this.$emit('modal-closed', modalName);
      this.closeModal(modalName);
    },
  },
}
</script>

<style scoped>

</style>