<template>
  <the-new-header/>
  <div class="page-content keyword-explorer">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">
      <div class="new-container">
        <h1 class="page-title">
          <template v-if="!showFirstScreen">{{ title }}</template>
        </h1>
      </div>

      <div class="keyword-explorer-content"
             :class="{'first-screen': showFirstScreen}">
          <div class="new-container">
            <template v-if="showFirstScreen">
              <div class="page-intro-search-block">
                <div class="block-slogan">
                  Unlock keyword insights. Enter any keyword and analyze its performance:
                </div>
                <div class="search-input-wrap">
                  <form>
                    <label>
                      <input type="text"
                             class="search-input"
                             @input.prevent
                             @keydown.prevent.enter="inputSearchHandler"
                             placeholder="Enter any keyword"
                             ref="searchInput"
                             autofocus
                             v-model="currentKeyword">
                    </label>
                  </form>

                  <base-button height="40px"
                               width="120px"
                               font-size="15px"
                               border-radius="4px"
                               :mode="'btn-standard'"
                               @click="inputSearchHandler">Analyze
                  </base-button>
                </div>

                <template v-if="suggestsLoading">
                  <div class="preloader-wrap">
                    <preloader-table-small loader-size="20px"></preloader-table-small>
                  </div>
                </template>
                <template v-else>
                  <template v-if="recommendedSuggestedKeywordsNotAdded?.length > 0">
                    <div class="recommended-keywords-block">
                      <div class="block-subtitle">Or choose from keywords below:</div>
                      <div class="suggested-keywords-block">
                        <div class="suggested-keywords">
                          <custom-chips
                              @chip-added="addChips(chip)"
                              v-for="(chip, idx) in recommendedSuggestedKeywordsNotAdded"
                              :selectable="true"
                              :is-selected="false"
                              :is-frozen="true"
                              :key="idx"
                              :amount="+chip?.sap">{{ chip.keyword }}
                          </custom-chips>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>

              </div>
            </template>

            <template v-else>
              <keyword-explorer-component
                  :currentKeyword="currentKeyword"
                  :update-explore-component="updateExploreComponent">
                <div class="input-with-translate">
                  <div class="search-input-wrap">
                    <form>
                      <label>
                        <input type="text"
                               class="search-input"
                               @input.prevent
                               @keydown.prevent.enter="inputSearchHandler"
                               placeholder="Search keyword..."
                               ref="liveSearchInput"
                               autofocus
                               v-model="currentKeyword">
                      </label>
                    </form>
                    <svg-icon class="search-icon"
                              icon="search-solid"
                              @click="inputSearchHandler"/>
                  </div>

                  <translate-tool :active="translateIsActive" @translate-clicked="translateClickHandler">
                    <template v-slot:text>
                      Translate keyword into English
                    </template>
                  </translate-tool>
                </div>
                <div class="translated-block">{{ currentKeywordTranslation }}</div>
              </keyword-explorer-component>
            </template>
          </div>
      </div>
    </main>
  </div>
</template>
<script>
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import CustomChips from "@/components/Forms/CustomChips";
import KeywordExplorerComponent from "@/components/KeywordExplorerComponent/index.vue";
import ExplorerModal from "@/components/KeywordExplorerComponent/ExplorerModal/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";

export default {
  name: "KeywordExplorer",
  components: {
    TranslateTool,
    'custom-chips': CustomChips,
    'keyword-explorer-component': KeywordExplorerComponent,
    'keyword-explorer-modal': ExplorerModal,
    PreloaderTableSmall,
    DetailsModal,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      showFirstScreen: true,
      searchResultsLoading: false,
      currentKeyword: '',
      recommendedSuggestedKeywords: [],
      recommendedSuggestedKeywordsAddedAll: false,
      currentKeywordCountryName: '',
      currentKeywordCountryCode: 'US',
      currentKeywordCountryId: null,
      addedKeywordsArray: [],
      addedSecondaryKeywordsArray: [],
      initialSecondaryKeywordsArrayLength: 0,
      suggestedKeywords: [],
      updateExploreComponent: 0,
      showAppDetailsModal: false,
      suggestsLoading: true,
      translateIsActive: false,
      currentKeywordTranslation: null,
    }
  },
  async mounted() {
    this.currentKeywordCountryName = this.currentCountry?.name;
    this.currentKeywordCountryCode = this.currentCountry?.code;
    this.currentKeywordCountryId = this.currentCountry?.id;

    if (window.location.search !== '') {
      let queryString = window.location.search.charAt(0).replace('?', '') + window.location.search.slice(1);
      const keyword = queryString.split('=')[1] ?? null;

      if (keyword) {
        this.currentKeyword = history.state?.keyword ? decodeURIComponent(history.state?.keyword) : decodeURIComponent(keyword);
        this.showFirstScreen = false;

        if (history.state?.keyword) {
          window.history.replaceState({}, '', '?keyword=' + history.state?.keyword);
        }
      }
    }

    if (this.showFirstScreen) {
      this.suggestsLoading = true;
      await this.fetchRecommendedSuggestKeywords();
      this.suggestsLoading = false;
    }
  },
  methods: {
    keywordsChipsInputChanged(e) {
      this.keywordsChipsInput = e;
    },
    addChips(chip) {
      this.currentKeyword = chip?.keyword;
      this.replaceUrl();
      this.showFirstScreen = false;
      this.updateExploreComponent++;
    },
    async fetchRecommendedSuggestKeywords(appId, countryCode) {
      this.recommendedSuggestedKeywordsAddedAll = false;
      this.recommendedSuggestedKeywords = [];
      const applicationId = appId ?? this.currentApp.id;
      const currCountryCode = countryCode ?? this.currentCountry?.code;

      this.modalDataLoader = false;
      const urlQuery = '?app_id=' + applicationId + '&country_code=' + currCountryCode + '&sort_by=sap&size=10&skip=0';
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.recommendedKeywords.RECOMMENDED_KEYWORDS + urlQuery);
      let selected = 0;
      const suggestedKeywordsArray = result?.list?.map(suggestion => {
        if (suggestion.selected) {
          selected++;
        }
        return {
          ...suggestion,
          keyword: suggestion?.keyword?.term,
          frozen: suggestion.selected,
        };
      });

      this.recommendedSuggestedKeywords = suggestedKeywordsArray?.filter(item => item?.sap > 20);
      this.recommendedSuggestedKeywordsAddedAll = selected === this.recommendedSuggestedKeywords?.length;
      this.modalDataLoader = true;
    },
    async inputSearchHandler() {
      if (this.currentKeyword.length === 0) {
        return;
      }

      this.replaceUrl();
      let queryString = window.location.search.charAt(0).replace('?', '') + window.location.search.slice(1);
      const keyword = queryString.split('=')[1] ?? null;

      if (keyword) {
        this.currentKeyword = decodeURIComponent(keyword);
        this.showFirstScreen = false;
        this.updateExploreComponent++;
      }

    },
    replaceUrl() {
      if (window.history.replaceState) {
        let queryString = '?keyword=' + this.currentKeyword;
        window.history.replaceState({keyword: this.currentKeyword}, '', queryString);
      }
    },
    openExplorerModal() {
      this.showAppDetailsModal = !this.showAppDetailsModal;
    },
    detailsModalClosed() {
      this.showAppDetailsModal = false;
    },
    setDocumentTitle() {
      let title = this.$route.meta.pageTitle;
      if (this.currentKeyword) {
        title = this.currentKeyword + ' - ' + this.storeTitle + ' ' + title;
      }
      this.setPageTitle(title);
    },
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.currentKeywordTranslation = null;
      } else {
        await this.translateKeyword();
      }
      this.translateIsActive = !this.translateIsActive;
    },
    async translateKeyword() {
      this.currentKeywordTranslation = await translates.translateKeyword(this, this.currentApp?.store?.key, this.currentKeyword);
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'promiseSettledData',
      'sidebarIsCollapsed',
    ]),
    recommendedSuggestedKeywordsNotAdded() {
      return this.recommendedSuggestedKeywords?.filter(item => {
        const itemIsInArray = this.addedSecondaryKeywordsArray?.some(subItem => subItem?.name === item?.keyword);
        if (!itemIsInArray) {
          return item;
        }
      });
    },
    storeTitle() {
      return this.currentApp?.store?.key === "APP_STORE" ? "App store" : "Google Play";
    }
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        if (this.showFirstScreen) {

          setTimeout(async () => {
            this.suggestsLoading = true;
            await this.fetchRecommendedSuggestKeywords(newVal?.id);
            this.suggestsLoading = false;
          }, 500);
        }
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        if (this.showFirstScreen) {
          this.suggestsLoading = true;
          await this.fetchRecommendedSuggestKeywords(null, newVal?.code);
          this.suggestsLoading = false;
        }
      }
    },
    currentKeyword() {
      this.setDocumentTitle();
      if (this.translateIsActive) {
        this.translateKeyword();
      }
    },
    storeTitle() {
      this.setDocumentTitle();
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>